"use client";
/* eslint-disable jsx-a11y/alt-text */

import Image, { ImageProps } from "next/image";
import { useState } from "react";

import styles from "./ImageWithNoImage.module.scss";
import NoImage from "./NoImage.svg";

/**
 * Next/Image のラッパーコンポーネント
 *
 * 画像の読み込みに失敗したときにはデフォルト画像（NoImage）を表示する.
 */
const ImageWithNoImage: React.FunctionComponent<ImageProps> = (props) => {
  // props.src がない場合はその時点で読み込み失敗にする.
  const defaultLoadFailed = !props.src;
  const [loadFailed, setLoadFailed] = useState(defaultLoadFailed);

  const handleOnError = () => {
    setLoadFailed(true);
  };

  // preload では画像の読み込みに失敗しても onerror イベントが発火しないため、
  // 読み込んだデータのサイズが 0x0 の場合は画像がセットされていないものとみなす.
  const handleOnLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const imgElement = event.target as HTMLImageElement;

    if (imgElement.naturalWidth === 0 || imgElement.naturalHeight === 0) {
      setLoadFailed(true);
    }
  };

  return (
    <>
      {loadFailed ? (
        <span className={styles.noImage} style={props.style}>
          <span className={styles.imageWrapper}>
            <NoImage />
          </span>
        </span>
      ) : (
        <Image {...props} onError={handleOnError} onLoad={handleOnLoad}></Image>
      )}
    </>
  );
};

export default ImageWithNoImage;
