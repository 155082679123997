"use client";

import { useLiveQuery } from "dexie-react-hooks";
import { usePathname } from "next/navigation";
import { useContext } from "react";
import TagManager from "react-gtm-module";
import { LOCAL_STORAGE_KEYS } from "~/const/localStorage";
import { SurveyPopupContext } from "~/contexts";
import { FavoriteRoom, ExceedMaxFavoriteCountError } from "~/db/models/FavoriteRoom";
import { FirstVisitTimestamp } from "~/features/visitor-context/FirstVisitTimestamp";
import dayjs from "~/lib/dayjs";

type FavoriteToggleProps = {
  roomId: number;
  roomUrl: string;
  roomTitle: string;
  roomNumber: string;
  roomArea: string;
  activeElement: JSX.Element;
  inactiveElement: JSX.Element;
  className?: string;
};

type SendDataType = {
  roomId: number;
  roomUrl: string;
  roomTitle: string;
  roomNumber: string;
  roomArea: string;
  isFavorite: boolean;
  currentUrl: string;
};

/**
 * お気に入りボタンの機能を提供するコンポーネント
 *
 * [使用方法]
 *
 * ```
 * <FavoriteToggle
 *   roomId={room.id}
 *   activeElement={
 *     <div>ActiveHeart</div>
 *   }
 *   inactiveElement={
 *     <div>InactiveHeart</div>
 *   }
 * ></FavoriteToggle>
 * ```
 */
const FavoriteToggle = ({
  roomId,
  roomUrl,
  roomTitle,
  roomNumber,
  roomArea,
  className,
  activeElement,
  inactiveElement,
}: FavoriteToggleProps): JSX.Element => {
  const pathname = usePathname();
  const currentUrl = `${process.env.NEXT_PUBLIC_FRONT_URL}${pathname}`;

  const favoriteRoom = useLiveQuery(() => FavoriteRoom.get(roomId));
  const isFavorite = Boolean(favoriteRoom?.createdAt);

  const { setSurveyPopupIsOpen } = useContext(SurveyPopupContext);

  const handleClickFavorite = async () => {
    const nextIsFavorite = !isFavorite;

    try {
      if (nextIsFavorite) {
        await FavoriteRoom.add(roomId);
        await openSurveyPopupIfNeeded();
      } else {
        await FavoriteRoom.delete(roomId);
      }
    } catch (e) {
      if (e instanceof ExceedMaxFavoriteCountError) {
        alert(e.message);
      } else {
        alert("お気に入り登録に失敗しました。時間を置いてから再度お試しください。");
      }
      return;
    }

    sendDataToTagManager({
      roomId,
      roomUrl,
      roomTitle,
      roomNumber,
      roomArea,
      isFavorite: nextIsFavorite,
      currentUrl,
    });
  };

  const openSurveyPopupIfNeeded = async () => {
    const POPUP_OPEN_COND = {
      FAVOTITE_COUNT: 3,
      DAYS_SINCE_FIRST_VISIT: 5,
    };

    const surveyPopupOpenFlag = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_OPEN_SURVEY_POPUP_FLAG);
    const favoriteCount = await FavoriteRoom.getCount();
    const firstVisitTimestamp = FirstVisitTimestamp.instance.value();

    if (
      surveyPopupOpenFlag !== "true" &&
      favoriteCount >= POPUP_OPEN_COND.FAVOTITE_COUNT &&
      dayjs().diff(firstVisitTimestamp, "day") >= POPUP_OPEN_COND.DAYS_SINCE_FIRST_VISIT
    ) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_OPEN_SURVEY_POPUP_FLAG, "true");
      setSurveyPopupIsOpen(true);
    }
  };

  return (
    <div
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleClickFavorite();
      }}
    >
      {isFavorite ? activeElement : inactiveElement}
    </div>
  );
};

/**
 * GTMへのデータ送信
 */
const sendDataToTagManager = ({
  roomId,
  roomUrl,
  roomTitle,
  roomNumber,
  roomArea,
  isFavorite,
  currentUrl,
}: SendDataType) => {
  const dataLayerArgs = {
    dataLayer: {
      event: "clickFavorite",
      eventProps: {
        category: "favorite",
        action: isFavorite ? "add" : "remove",
        label: currentUrl,
        room_id: roomId,
        url: roomUrl,
        room_title: roomTitle,
        room_number: roomNumber,
        room_area: roomArea,
      },
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

export default FavoriteToggle;
